import React from "react";
import "./BetCard.styled";
import { getSportIcon } from '../../utils/events'
import { getBetStatus } from '../../utils/bets'
import {
  BetButton, BetCardContainer, BetCardProfit, BetCardRow, BetCardTable, BetCardTitle, BetDate,
  ClockIcon, Column, Match, MatchOverview, Profit, ProfitBadgeText, ProfitColumn, RowContentContainer, SportIcon, BetEmail
} from './BetCard.styled'

const getPct = arb =>( (1 - ((1 / arb.bets[0]?.odds) + (1 / arb.bets[1]?.odds))) * 100).toFixed(2);
import { useTranslation } from 'react-i18next';

export const BetCard = ({ bet, showUser }) =>{
  const { t } = useTranslation();

  return  (
    <BetCardContainer className="card">
      <MatchOverview>
        <Match>
          <SportIcon src={getSportIcon(bet.event.sport)}/>
          {bet.event ? bet.event.name : bet.eventName}
          <ProfitBadgeText className="badge badge-secondary">
            {bet.pct.toFixed(2)}%
          </ProfitBadgeText>
          { showUser && !isNaN(getPct(bet)) && <ProfitBadgeText className="badge badge-secondary">
            {getPct(bet)}%
          </ProfitBadgeText>
          }
        </Match>
      </MatchOverview>
      <BetDate>
        <ClockIcon className="far fa-clock" />{" "}
        { new Date(bet.timestamp).toLocaleString() }
      </BetDate>
      { showUser && 
        <BetEmail>
          <ClockIcon className="far fa-user" />{" "}
          {bet._id.email} | {bet._id.version}
        </BetEmail> }
      <BetCardTable>
        <Column small={true}>
          <BetCardTitle>Bookies</BetCardTitle>
          <RowContentContainer>
            { bet.bets.map(bet => <BetCardRow>{bet.bookie}{showUser && ` | ${bet.duration}ms`}</BetCardRow>) }
          </RowContentContainer>
        </Column>
        <Column className="column">
          <BetCardTitle>{t('Name')}</BetCardTitle>
          <RowContentContainer>
            {bet.bets.map(bet => (
              <BetCardRow small={true}>
                <a>{bet.name}</a>
              </BetCardRow>
            ))}
          </RowContentContainer>
        </Column>
        <Column small={true}>
          <BetCardTitle>{t('odds')}</BetCardTitle>
          <RowContentContainer>
            {bet.bets.map((bet) => (
              <BetCardRow small={true}>
                {Math.round(bet.odds * 100) / 100}
              </BetCardRow>
            ))}
          </RowContentContainer>
        </Column>
      </BetCardTable>
    </BetCardContainer>
  );

}