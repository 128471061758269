import React from "react";
import './AddAccountCard.styled'
import { AddBookieContainer, AddCardBody, AddCardContainer, AddText, PlusIcon } from "./AddAccountCard.styled";

export const AddAccountCard = ({ onClick }) => {
        return (
            <AddCardContainer className="card" onClick={onClick}>
                <AddCardBody className="card-body">
                    <AddBookieContainer>
                        <PlusIcon>+</PlusIcon>
                        <AddText>Añadir cuenta</AddText>
                    </AddBookieContainer>
                </AddCardBody>
            </AddCardContainer>
        );
}