import React from "react";
import "./BetCard.styled";
import { getSportIcon } from '../../utils/events'
import { getBetStatus } from '../../utils/bets'
import {
  BetButton, BetCardContainer, BetCardProfit, BetCardRow, BetCardTable, BetCardTitle, BetDate,
  ClockIcon, Column, Match, MatchOverview, Profit, ProfitBadgeText, ProfitColumn, RowContentContainer, SportIcon, BetEmail
} from './BetCard.styled'

const getPct = arb =>( (1 - ((1 / arb.bets[0]?.odds) + (1 / arb.bets[1]?.odds))) * 100).toFixed(2);

export const BetCard = ({ bet, setSettledBet, showUser }) => (
      <BetCardContainer className="card">
        <MatchOverview>
          <Match>
            <SportIcon src={getSportIcon(bet.sportName)}/>
            {bet.event ? bet.event.name : bet.eventName}
            <ProfitBadgeText className="badge badge-secondary">
              {bet.profitPercentage}%
            </ProfitBadgeText>
            { showUser && !isNaN(getPct(bet)) && <ProfitBadgeText className="badge badge-secondary">
              {getPct(bet)}%
            </ProfitBadgeText>
            }
          </Match>
          {new Date().getTime() - new Date(bet.timestamp).getTime() > 24 * 60 * 60 * 1000 &&
            bet.status !== "SETTLED" && (
              <BetButton
                type="button"
                className="btn btn-danger"
                onClick={() => setSettledBet(bet)}
              >
                Confirmar apuesta
              </BetButton>
            )}
        </MatchOverview>
        <BetDate>
          <ClockIcon className="far fa-clock" />{" "}
          { new Date(bet.timestamp).toLocaleString() }
        </BetDate>
        { showUser && 
          <BetEmail>
            <ClockIcon className="far fa-user" />{" "}
            {bet._id.email} | {bet._id.version}
          </BetEmail> }
        <BetCardTable>
          <Column small={true}>
            <BetCardTitle>Bookies</BetCardTitle>
            <RowContentContainer>
              { bet.bets.map(bet => <BetCardRow>{bet.bookie}{showUser && ` | ${bet.duration}ms`}</BetCardRow>) }
            </RowContentContainer>
          </Column>
          <Column className="column">
            <BetCardTitle>Nombre</BetCardTitle>
            <RowContentContainer>
              {bet.bets.map(bet => (
                <BetCardRow small={true}>
                  <a>{bet.name}</a>
                  <span className={getBetStatus(bet)} />
                </BetCardRow>
              ))}
            </RowContentContainer>
          </Column>
          <Column small={true}>
            <BetCardTitle>Stake</BetCardTitle>
            <RowContentContainer>
              {bet.bets.map(bet => (
                <BetCardRow small={true}>
                  { Math.round(bet.stake * 100) / 100 }
                </BetCardRow>
              ))}
            </RowContentContainer>
          </Column>
          <Column small={true}>
            <BetCardTitle>Cuotas</BetCardTitle>
            <RowContentContainer>
              {bet.bets.map((bet) => (
                <BetCardRow small={true}>
                  {Math.round(bet.odds * 100) / 100}
                </BetCardRow>
              ))}
            </RowContentContainer>
          </Column>
          {bet.profit !== undefined && (
            <ProfitColumn>
              <Profit>Beneficio</Profit>
              <BetCardProfit>
                {Math.round(bet.profit * 100) / 100}
              </BetCardProfit>
            </ProfitColumn>
          )}
        </BetCardTable>
      </BetCardContainer>
    );
