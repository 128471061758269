import React, { useEffect, useState } from "react";
import { NavBarMain } from "..";
import "./AddBookie.styled";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import { bookieForm, editBookieForm } from "../../schemas/index";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useUser } from "../../hooks";
// import { getErrorMessage } from '../../utils/bookies'
import { PageContainer, BookieInfoContainer, Button } from "./AddBookie.styled";
import { checkIfTokenStillValid } from "../../repositories/utils";
import { useUsers } from "../../contexts/UsersContext";

export const AddBookie = ({ history, match }) => {
  const { addBookie } = useUser()
  const { mutate } = useUsers();

  // const [error, setError] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [show, setShow] = useState(false);

  useEffect(() => {
    checkIfTokenStillValid(history)
  }, [])

  const getAction = () => match.params.action;

  const handleAddBookie = async values => {
    const { email, bookie, action } = match.params;
    const { username, password, enable, date, birthdate } = values;
    try {
      await addBookie({ email, bookie, action, username, password, enable, date, birthdate }).then(response => response.ok && history.push(`/bookies/${email}`));
      mutate();
    } catch (err) {
      // setError({ error: err.response.data ||  err.response.data.error });
      console.log(err)
    }
  };

  return (
    <PageContainer>
      <NavBarMain currentPage="bothome" history={history}/>
      <BookieInfoContainer>
        <Formik
          validationSchema={getAction() === 'add' ? bookieForm : editBookieForm}
          initialValues={getAction() === 'add' ? { username: "", password: "", enable: false } : { password: "", enable: false }}
          onSubmit={async values => handleAddBookie(values)}
        >
          {({
            handleSubmit,
            handleChange,
            touched,
            errors,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Form.Group controlId="username">
                <Form.Label>Usuario</Form.Label>
                <Form.Control
                  placeholder="Usuario"
                  value={values.username}
                  onChange={handleChange}
                  isValid={touched.username && !errors.username}
                />
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control
                  placeholder="Contraseña"
                  value={values.password}
                  onChange={handleChange}
                  isValid={touched.password && !errors.password}
                />
                <Form.Text style={{ color: "#758B78" }}>
                  Tu contraseña está segura en nuestros servidores.
                </Form.Text>
              </Form.Group>
              {match.params.bookie === 'winamax' && <Form.Group controlId="birthdate">
                <Form.Label>Fecha de Nacimiento</Form.Label>
                <Form.Control
                  type="date"
                  value={values.birthdate}
                  onChange={handleChange}
                  isValid={touched.birthdate && !errors.birthdate}
                />
              </Form.Group>
              }
              <Form.Group controlId="enableControl">
                <Form.Label>Activar</Form.Label>
                <Form.Control
                  as="select"
                  value={values.enableControl}
                  onChange={val => {
                    handleChange(val);
                    if (val.nativeEvent.target.selectedIndex === 1) {
                      setFieldValue("enable", true);
                      setShow(false);
                    } else if (val.nativeEvent.target.selectedIndex === 2) {
                      setFieldValue("enable", false);
                      setShow(true);
                    } else {
                      setFieldValue("enable", false);
                      setShow(false);
                    }
                  }}
                  label="Activar"
                >
                  <option>No Activar</option>
                  <option>Activar ahora</option>
                  <option>Programar Activación</option>
                </Form.Control>
              </Form.Group>
              {show && (
                <Form.Group controlId="enable">
                  <Form.Label>Fecha de activación</Form.Label>
                  <DatePicker
                    className="form-control"
                    selected={startDate}
                    onChange={(date) => {
                      setFieldValue("date", date);
                      setStartDate(date.getTime());
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    minDate={new Date()}
                  />
                </Form.Group>
              )}
              <Button type="submit" className="btn-secondary" >
                {getAction() === "add" ? "+ Añadir" : "Editar"}
              </Button>
            </form>
          )}
        </Formik>
      </BookieInfoContainer>
    </PageContainer>
  );
};
