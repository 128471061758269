import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import { creditForm } from "../../schemas";

export const AddCredit = ({ handleAddCredit }) => {
    return (
        <>
            <Formik
                validationSchema={creditForm}
                initialValues={{ amount: "", rate: 30, method: "revolut" }}
                onSubmit={async (values) => handleAddCredit(values)}
            >
                {({ handleSubmit, handleChange, touched, errors, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Form.Group controlId="amount">
                            <Form.Label>Cantidad Pagada</Form.Label>
                            <Form.Control
                                placeholder="€"
                                value={values.amount}
                                onChange={handleChange}
                                type="number"
                                isValid={touched.amount && !errors.amount}
                            />
                        </Form.Group>
                        <Form.Group controlId="rate">
                            <Form.Label>% Sobre Beneficio</Form.Label>
                            <Form.Control
                                placeholder="%"
                                value={values.rate}
                                onChange={handleChange}
                                type="number"
                                isValid={touched.rate && !errors.rate}
                            />
                        </Form.Group>
                        <Form.Group controlId="method">
                            <Form.Label>Método de Pago</Form.Label>
                            <Form.Control
                                as="select"
                                value={values.method}
                                onChange={handleChange}
                                label="Método de Pago"
                            >
                                <option value="revolut">Revolut</option>
                                <option value="cash">Cash</option>
                                <option value="paypal">PayPal</option>
                                <option value="skrill">Skrill</option>
                                <option value="bankTransfer">Transferencia Bancaria</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="reference">
                            <Form.Label>Referencia pago (Opcional)</Form.Label>
                            <Form.Control
                                placeholder="Referencia"
                                value={values.reference}
                                onChange={handleChange}
                                type="string"
                                isValid={touched.reference && !errors.reference}
                            />
                        </Form.Group>
                        <Button type="submit">Añadir</Button>
                    </form>
                )}
            </Formik>
        </>
    );
}