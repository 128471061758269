import styled from "styled-components";

export const BetCardContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 30px;
  padding: 15px;
  background-color: rgb(29,45,29);
  border-radius: 5px;
`

export const MatchOverview = styled.div`
  display: flex;
  padding-left: 20px;

  @media only screen and (max-width: 700px) {
    padding-left: 5px;
  }
`

export const Match = styled.div`
  font-weight: 600;
  display: flex;
  align-items: flex-end;
  font-size: 16px;
`

export const ProfitBadgeText = styled.span`
  font-size: medium;
  margin-left: 5px;
`

export const SportIcon = styled.img`
  padding-right: 5px;
  margin-bottom: 3px;
  height: 17px;
  filter: invert(100%) sepia(21%) saturate(0%) hue-rotate(4deg) brightness(108%) contrast(101%);
`

export const BetButton = styled.button`
  position: absolute;
  right: 10px;
`

export const BetDate = styled.div`
    display: flex;
    padding-left: 20px;
    align-items: center;
    margin-top: 5px;

    @media only screen and (max-width: 700px) {
      padding-left: 5px;
    }
`

export const BetEmail = styled.div`
    display: flex;
    padding-left: 20px;
    align-items: center;
    margin-top: 5px;

    @media only screen and (max-width: 700px) {
      padding-left: 5px;
    }
`

export const ClockIcon = styled.i`
  margin-right: 5px;
`

export const BetCardTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 20px;
  padding-bottom: 30px;
`

export const Column = styled.div`
  height: ${props => props.small ? 'auto' : 'fit-content'}
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1 1 60px;
`

export const BetCardTitle = styled.div`
  display: flex;
  justify-content: center;
  height: 30px;
  font-size: 15px;
  font-weight: 400;
`

export const BetCardRow = styled.div`
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  flex-grow: ${props => props.small ? 1 : 0};

  .status {
    margin-left: 5px;
  }

  .status-circle {
    height: 10px;
    width: 10px;
    border-radius: 5px;
    margin-top: 3px;
  }
`

export const ProfitColumn = styled.div``

export const Profit = styled.div`
  display: flex;
  justify-content: center;
`

export const BetCardProfit = styled.div`
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 25px;
`

export const RowContentContainer = styled.div`
  height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around
`
