import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "gilroyregular", sans-serif;
  min-height: 100vh;
  padding: 20px;

  background: ${props => !props.inverted ? '#111E12' : 'white'};
  color: ${props => !props.inverted ? '#e7e7e7' : '#111e12'};
`;

export const Subcontainer = styled.div`
  display: flex;
  max-width: 1600px;
  width: 100%;
  margin: auto;
  margin-top: 30px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: justify;

  @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }


  img {
    max-width: 100%;
    width: 550px;
  }
`;

export const ChaptersContainer = styled.div`
  width: 100%;
  max-width: 400px;
  position: sticky;
  top: 0;
  padding-top: 20px;
  padding-right: 50px;  


  @media (max-width: 768px) {
      display: none;
   }

  a {
    color: ${props => !props.inverted ? '#e7e7e7' : '#111e12'};
    font-size: 17px;
    letter-spacing: -0.02em;
   }
  
   a:hover {
    text-decoration: none;
    color: #00bc8c;
   }
`;

export const Chapter = styled.div`
`;

export const TextContainer = styled.div`
  width: 100%;
  max-width: 900px;
  font-size: 20px;
  line-height: 30px;

  table {
    background: #111E12;
    font-size: 17px;
    text-align: left;
  }

  .green {
    background: #3e923e;
  }

  .red {
    background: #ff4040;
  }
`;

export const ReadingModeToggle = styled.div`
  margin-top: 20px;
  font-weight: bold;
  width: 40px;

  button {
    color: #00bc8c;

    font-size: 27px;
    background: ${props => props.inverted ? '#111E12' : 'white'};
    border-radius: 10px;

    :hover {
      cursor: pointer;
      color: #649b8d;
    }
  }

  :hover {
    cursor: pointer;
    color: #00bc8c;
  }

  svg {
    margin-top: -5px;
  }
`;

export const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 30px 0px;

  width: 100%;

  h5, h2 {
    color: ${props => props.inverted ? '#e7e7e7' : '#111e12'};
  } 

  a {
    color: ${props => props.inverted ? '#e7e7e7' : '#111e12'};
    font-size: 17px;
    letter-spacing: -0.02em;
   }
  
   a:hover {
    text-decoration: none;
    color: #00bc8c;
   }
`;

export const NextNavigation = styled.div`
   background: ${props => props.inverted ? '#111E12' : 'white'};
   padding: 10px;
   border-radius: 10px;
`;