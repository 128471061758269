// UserHomePage.js
import React, { useState, useEffect } from "react";
import ScrollSpy from "react-ui-scrollspy";
import { NavBarMain } from "..";
import "@theme-toggles/react/css/Classic.css"
import { Classic } from "@theme-toggles/react"
import ReactHtmlParser from "html-react-parser";
import { useUser } from "../../hooks";

import {
  PageContainer,
  ChaptersContainer,
  TextContainer,
  Subcontainer,
  Chapter,
  ReadingModeToggle,
  Navigation,
  NextNavigation
} from './AcademyModule.styled';

// The component itself
export const AcademyModule = ({ history, match }) => {
  const { getChapters } = useUser()
  const [inverted, setInverted] = useState(false);
  const [chapters, setChapters] = useState(undefined);

  const getChapter = () => {
    return chapters && chapters[match.params.module] ? chapters[match.params.module][match.params.chapter] : undefined;
  }

  useEffect(async () => {
    await getChapters().then(setChapters)
  }, [])

  useEffect(() => {
    if (chapters !== undefined && !getChapter()) {
      history.push("/academy");
    }
  }, [chapters])

  const onPress = (e) => {
    e.preventDefault();
    const target = window.document.getElementById(
      e.currentTarget.href.split("#")[1]
    );
    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <PageContainer inverted={inverted}>
      <NavBarMain currentPage="academy" inverted={inverted} history={history} />
      <Subcontainer >
        <ChaptersContainer inverted={inverted}>
          {getChapter() && getChapter().subchapters.map((chapter) => (
            <Chapter>
              <a
                data-to-scrollspy-id={chapter.id}
                onClick={(e) => onPress(e)}
                href={`#${chapter.id}`}
              >
                {chapter.title}
              </a>
            </Chapter>
          ))}
          <ReadingModeToggle inverted={inverted} onClick={() => setInverted(!inverted)}><Classic toggled={!inverted} duration={750} /></ReadingModeToggle>
        </ChaptersContainer>
        <TextContainer inverted={inverted}>
          {getChapter() && getChapter().text && < ScrollSpy scrollThrottle={100} updateHistoryStack={false}>
            {ReactHtmlParser(getChapter().text)}
          </ScrollSpy>
          }
          <Navigation inverted={inverted}>
            {
              getChapter() && getChapter().next && <NextNavigation inverted={inverted}>
                <h5>Siguiente:</h5>
                <h2>{getChapter().next.text}</h2>
                <a href={getChapter().next.link}>Ir al módulo</a>
              </NextNavigation>
            }
          </Navigation>
        </TextContainer>
      </Subcontainer>
    </PageContainer>
  );
};